"use client";

import type { TCountryCode } from "countries-list";
import { getCountryCode } from "countries-list";

import { IANA_TIME_ZONE_TO_COUNTRY } from "./timeZoneToCountry";

export const getCountryFromTimezone = (): TCountryCode => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const country = IANA_TIME_ZONE_TO_COUNTRY[timeZone as keyof typeof IANA_TIME_ZONE_TO_COUNTRY];
  const countryCode = getCountryCode(country);

  return countryCode || "NO";
};
