import dayjs from "dayjs";
import dayOfYear from "dayjs/plugin/dayOfYear";
import relativeTime from "dayjs/plugin/relativeTime";
import timezone from "dayjs/plugin/timezone";

import "dayjs/locale/nb";
import "dayjs/locale/da";

import { type SupportedLanguage } from "./i18n";

dayjs.extend(relativeTime);
dayjs.extend(dayOfYear);
dayjs.extend(timezone);

const t = {
  no: {
    future: "om %s",
    past: "%s siden",
    s: "noen sekunder",
    m: "ett minutt",
    mm: "%d minutter",
    h: "en time",
    hh: "%d timer",
    d: "en dag",
    dd: "%d dager",
    M: "en måned",
    MM: "%d måneder",
    y: "ett år",
    yy: "%d år",
  },
  dk: {
    future: "om %s",
    past: "%s siden",
    s: "nogle sekunder",
    m: "et minut",
    mm: "%d minutter",
    h: "en time",
    hh: "%d timer",
    d: "en dag",
    dd: "%d dage",
    M: "en måned",
    MM: "%d måneder",
    y: "et år",
    yy: "%d år",
  },
  en: {},
} satisfies { [key in SupportedLanguage]: Record<string, string> };

export const relativeToNow = (language: SupportedLanguage, date: Date) => {
  if (language === "en") {
    return dayjs().to(date);
  }
  return dayjs().locale(language, { relativeTime: t[language] }).to(date);
};

export const relativeFromNow = (language: SupportedLanguage, date: Date) => {
  if (language === "en") {
    return dayjs(date).fromNow();
  }
  return dayjs(date).locale(language, { relativeTime: t[language] }).fromNow();
};

export const getDayJsOfLocale = (
  language: SupportedLanguage,
  existingDate?: Date | dayjs.Dayjs | string | number,
) => {
  const date = dayjs(existingDate);
  switch (language) {
    case "no":
      return date.locale("nb");
    case "dk":
      return date.locale("da");
    default:
      return date;
  }
};

export { dayjs };
